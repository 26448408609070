<script setup lang="ts">
  import {
    useFloating,
    offset,
    flip,
    shift,
    arrow,
    autoUpdate,
  } from '@floating-ui/vue';
  import { InformationCircleIcon } from '@heroicons/vue/24/outline'

  const props = withDefaults(defineProps<{
    isInteractive?: boolean
    floatingClasses?: string
  }>(), {
    isInteractive: false,
    floatingClasses: ''
  })

  const reference = ref<HTMLElement>()
  const floating = ref<HTMLElement>()
  const floatingArrow = ref<HTMLElement>()

  const { floatingStyles, middlewareData } = useFloating(reference, floating, {
    whileElementsMounted: autoUpdate,
    placement: 'top',
    middleware: [offset(10), flip(), shift(), arrow({ element: floatingArrow })],
  })

  const open = ref<boolean>(false);
  let closeTimeout: any = null;

  // Update the position programmatically if needed
  watch(open, () => {
    if (open.value) {
      cancelClose()
      showTooltip()
    } else {
      hideTooltip()
    }
  })

  function scheduleClose() {
    if (props.isInteractive) {
      closeTimeout = setTimeout(() => {
        open.value = false
      }, 100) // delay before closing the tooltip
    } else {
      open.value = false
    }
  }

  function cancelClose() {
    clearTimeout(closeTimeout)
  }

  function showTooltip() {
    if (floating.value) {
      floating.value.style.display = 'block'
    }
  }

  function hideTooltip() {
    if (floating.value) {
      floating.value.style.display = ''
    }
  }
</script>

<template>
  <span ref="reference" v-bind="$attrs" @mouseover="open = true" @mouseleave="scheduleClose">
    <slot name="reference">
      <InformationCircleIcon class="inline-block h-4 w-4 text-current" aria-hidden="true" />
    </slot>
  </span>
  <Teleport to="body" v-if="true">
    <div
      ref="floating"
      @mouseenter="isInteractive ? cancelClose() : undefined"
      @mouseleave="isInteractive ? scheduleClose() : undefined"
      :style="floatingStyles"
      :class="floatingClasses"
      class="hidden w-max max-w-xs py-1 px-2 rounded text-sm font-normal bg-gray-800 text-gray-200 z-50"
      style="text-wrap: wrap;"
      >    
      <slot name="floating">Floating</slot>
      <div ref="floatingArrow" :style="{left: middlewareData.arrow?.x != null ? `${middlewareData.arrow.x}px` : ''}" :class="middlewareData.offset?.placement === 'bottom' ? '-top-1.5' : '-bottom-1.5'" class="absolute w-5 h-5 bg-gray-800 -z-[1] pointer-events-none rotate-45 rounded-[3px]"></div>
    </div>
  </Teleport>
</template>
